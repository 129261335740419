<template>
    <div class="search-box">
        <el-input
            v-model="input_value"
            @keyup.enter.native="handleFun({search: input_value,type: select_value})"
            class="search-input">
            <!-- 下拉 -->
            <el-select
                v-model="select_value"
                class="search-selected"
                @change="handleFun({search: input_value,type: select_value})"
                placeholder="请选择"
                slot="prepend">
                <el-option
                    label="全部"
                    value="0">
                </el-option>
                <el-option
                    v-for="item in selectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <!-- 搜索按钮 -->
            <el-button 
                slot="append" 
                icon="el-icon-search"
                class="search-button"
                @click="handleFun({search: input_value,type: select_value})">
            </el-button>
        </el-input>
        <slot name="searchFast"></slot>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                input_value: '',
                select_value: '',
                options: [],
            }
        },
        props: {
            searchValue: {
                type: String,
                default: ""
            },
            selectedValue: {
                type: String,
                default: ""
            },
            selectOptions: {
                type: Array,
                default: []
            }
        },
        watch: {
            searchValue: {
                handler: function(val) {
                    this.input_value = val;
                },
                immediate: true
            },
            selectedValue: {
                handler: function(val) {
                    this.select_value = val
                },
                immediate: true
            }
        },
        methods: {
            handleFun({ search, type }){
                this.$emit('handleSearch', {
                    search,
                    type,
                })
            }
        },
    }
</script>

<style scoped>
.search-input{
    border-radius: 30px;
	
}
.search-input >>> .el-input-group__prepend{
    background-color: transparent;
    border: 0;
}
.search-input >>> .el-input-group__append{
    background-color: #fff;
    border-color: var(--color);
    border-left-color: transparent;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.search-input >>> input{
    border-color: var(--color);
    border-right: 0;
}
/* 搜索框 */
.search-selected{
    width: 200px;
    background-color: var(--color);
    border-color: var(--color);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #fff;
}
.search-selected >>> input{
    text-align: center;
}
.search-selected >>> i{
    color: #fff !important;
}
/* 按钮 */
.search-button{
    padding: 12px;
	/* padding-left: 14px; */
    background-color: var(--color) !important;
    border-radius: 30px;
    color: #fff !important;
}
</style>